<template>
  <div class="home">
    <section class="hero is-info is-fullheight-with-navbar">
      <div class="hero-body" :style="backgroundImageStyle">
        <div class="container has-text-centered">
          <h1 class="title is-1">
            Switch Cribs: The Smart Way to Travel
          </h1>
          <h2 class="subtitle is-3">
            Tired of expensive hotels and vacation rentals? Swap your home and save money while exploring new destinations.
          </h2>
          <router-link to="/signup" class="button is-primary is-large">Get started for free!</router-link>
        </div>
      </div>
    </section>
    <section class="section listings-section">
      <div class="container">
        <h2 class="title has-text-centered">Browse Our Latest Listings</h2>
        <div class="columns is-multiline">
          <div class="column is-one-third" v-for="listing in listings.slice(0, 6)" :key="listing._id">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <router-link :to="'/house/' + listing._id">
                    <img :src="listing.photos[0]" alt="House photo">
                  </router-link>
                </figure>
              </div>
              <div class="card-content">
                <p class="title">{{ listing.title }}</p>
                <p class="subtitle">{{ listing.description }}</p>
                <router-link :to="'/house/' + listing._id" class="button is-link">View Details</router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="has-text-centered">
          <router-link to="/listings" class="button is-primary">View All Listings</router-link>
        </div>
      </div>
    </section>

    <section class="section features-section">
      <div class="container">
        <h2 class="title has-text-centered">Why Choose Home Swap?</h2>
        <div class="columns is-multiline">
          <div class="column is-one-third" v-for="feature in features" :key="feature.title">
            <div class="card">
              <div class="card-content has-text-centered">
                <p class="title is-4">
                  {{ feature.title }}
                </p>
                <p class="subtitle is-6">
                  {{ feature.subtitle }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section newsletter-section">
      <div class="container">
        <h2 class="title has-text-centered">Stay Updated with the Latest Listings</h2>
        <div class="columns is-centered">
          <div class="column is-half">
            <form @submit.prevent="subscribeNewsletter">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input class="input" type="email" v-model="newsletterEmail" placeholder="Enter your email" required>
                </div>
                <div class="control">
                  <button class="button is-primary" type="submit">Subscribe</button>
                </div>
              </div>
              <p class="help is-danger" v-if="error">{{ error }}</p>
              <p class="help is-success" v-if="success">{{ success }}</p>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="section testimonials-section" style="background-color: #f9f9f9;">
      <div class="container">
        <h2 class="title has-text-centered">What Our Users Are Saying</h2>
        <div class="columns is-multiline">
          <div class="column is-one-third" v-for="testimonial in testimonials" :key="testimonial.name">
            <div class="card">
              <div class="card-content">
                <p class="title is-5">{{ testimonial.feedback }}</p>
                <p class="subtitle is-6">- {{ testimonial.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section faq-section">
      <div class="container">
        <h2 class="title has-text-centered">Frequently Asked Questions</h2>
        <div class="content">
          <ul>
            <li v-for="faq in faqs" :key="faq.question">
              <strong>{{ faq.question }}</strong>
              <p>{{ faq.answer }}</p>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <footer class="footer" style="background-color: #333; color: #fff;">
      <div class="content has-text-centered">
        <p>
          <strong>Switch Cribs</strong> by <a href="https://taikalabs.tech" style="color: #fff;">Placeholder Ltd</a>. All rights reserved.
        </p>
        <div class="social-icons">
          <a class="icon" href="https://facebook.com">
            <i class="fab fa-facebook-f"></i>
          </a>
          <a class="icon" href="https://twitter.com">
            <i class="fab fa-twitter"></i>
          </a>
          <a class="icon" href="https://instagram.com">
            <i class="fab fa-instagram"></i>
          </a>
          <a class="icon" href="https://linkedin.com">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'Home',
  data() {
    return {
      features: [
        { title: 'Save Money', subtitle: 'Cut travel costs by swapping homes instead of booking expensive hotels or rentals.' },
        { title: 'Comfort & Convenience', subtitle: 'Stay in a fully equipped home with all the amenities you need.' },
        { title: 'Global Reach', subtitle: 'Join a community of home swappers from around the world.' }
      ],
      testimonials: [
        { name: 'Francine Simmons', feedback: 'Home Swap made my vacation so much better! Seamless experience.' },
        { name: 'Babatunde Emmanual', feedback: 'Highly recommend for anyone looking to swap homes. Very secure.' },
        { name: 'Samuel Nguyen', feedback: 'Excellent service and great customer support!' }
      ],
      faqs: [
        { question: 'How do I start swapping homes?', answer: 'Sign up, list your home, and start exploring swap opportunities.' },
        { question: 'Is my information safe?', answer: 'Yes, we use advanced security measures to protect your data.' },
        { question: 'Can I swap homes internationally?', answer: 'Absolutely! We have a global community of home swappers.' },
        { question: 'What happens if there is damage to my home?', answer: 'We provide an optional insurance plan to cover potential damages during the swap.' },
        { question: 'How do you ensure the security of my personal information?', answer: 'We use state-of-the-art encryption and security measures to protect your data.' },
        { question: 'Can I review and rate my swap experience?', answer: 'Yes, after the swap, both parties can review and rate their experience.' }
      ],
      listings: [],
      newsletterEmail: '',
      error: '',
      success: ''
    };
  },
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${require('@/assets/hero-image.jpg')})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      };
    }
  },
  created() {
    this.fetchListings();
  },
  methods: {
    fetchListings() {
      const token = localStorage.getItem('token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      api.get('/api/houses/all', { headers })
        .then(response => {
          this.listings = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    subscribeNewsletter() {
      if (!this.newsletterEmail) {
        this.error = 'Please enter a valid email address.';
        return;
      }
      // Here, you would typically send the email to your backend API.
      // For demonstration, we'll just show a success message.
      this.success = 'Subscribed successfully!';
      this.error = '';
      this.newsletterEmail = '';
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
  background-color: #ffffff;
  font-family: 'Inter', sans-serif;
}

.hero {
  color: #fff;
  padding: 3rem 1.5rem;
}

.features-section, .testimonials-section, .faq-section, .listings-section, .newsletter-section {
  padding: 3rem 1.5rem;
}

.card {
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  border-radius: 8px;
  margin: 1rem 0;
  transition: transform 0.2s;
  background-color: #fff;
}

.card:hover {
  transform: translateY(-5px);
}

.card-image img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.button.is-primary {
  background-color: #3273dc;
  border-color: transparent;
  color: #fff;
  border-radius: 8px;
}

.button.is-link {
  background-color: #00d1b2;
  border-color: transparent;
  color: #fff;
  border-radius: 8px;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 2rem 1.5rem;
}

.footer a {
  color: #fff;
  margin: 0 0.5rem;
}

.footer .social-icons a {
  margin: 0 0.5rem;
  font-size: 1.2rem;
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #3273dc;
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #333;
}

.hero .title, .hero .subtitle, .hero .button {
  color: #fff !important;
}

.hero .title {
  margin-bottom: 0.5rem;
}

.hero .subtitle {
  margin-bottom: 1.5rem;
}

.hero .button {
  background-color: #ff6f61;
  border-color: transparent;
}

.newsletter-section .field {
  margin-bottom: 1rem;
}

.help.is-danger, .help.is-success {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .add-house {
    padding: 1rem;
  }

  .field {
    margin-bottom: 1rem;
  }

  .button.is-primary, .button.is-link {
    padding: 0.5rem 1rem;
  }
}
</style>
