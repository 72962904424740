import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home.vue';
import NewHome from '@/views/NewHome.vue';
import Signup from '@/views/SignUp.vue';
import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import AddHouse from '@/views/AddHouse.vue';
import EditHouse from '@/views/EditHouse.vue';
import HouseDetails from '@/views/HouseDetails.vue';
import Messages from '@/components/Messages.vue';
import MessageDetail from '@/components/MessageDetail.vue'; // Import MessageDetail component
import Listings from '@/views/Listings.vue';


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'NewHome',
      component: NewHome
    },
    {
      path: '/old',
      name: 'Home',
      component: Home
    },
    {
      path: '/signup',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/register',
      name: 'Signup',
      component: Signup
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/signin',
      name: 'Login',
      component: Login
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/add-house',
      name: 'AddHouse',
      component: AddHouse
    },
    {
      path: '/edit-house/:id',
      name: 'EditHouse',
      component: EditHouse
    },
    {
      path: '/house/:id',
      name: 'house-details',
      component: HouseDetails,
      props: true
    },
    {
      path: '/messages',
      name: 'Messages',
      component: Messages,
    },
    {
      path: '/messages/:id',
      name: 'MessageDetail',
      component: MessageDetail
    },
    {
      path: '/listings',
      name: 'Listings',
      component: Listings
    }
  ]
});
