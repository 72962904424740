<template>
  <div class="house-detail">
    <section class="section">
      <div class="bg-white flex min-h-5 w-full justify-between items-center p-6">
        <button class="rounded-full flex gap-2">
          <span class="material-symbols-outlined"> arrow_back </span>
          <router-link :to="backLink" class="button is-link">
            {{ backButtonText }}
          </router-link>
        </button>

        <div>
          <h1 class="title">{{ house?.title }}</h1>
        </div>
      </div>
      <div class="container mx-auto" v-if="house">
        <section>
          <div
            class="flex flex-col items-center px-5 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8"
          >
            <div
              class="flex flex-col w-full max-w-3xl mx-auto prose text-left prose-blue"
            >
              <div class="w-full mx-auto">
                <p><strong>Description:</strong> {{ house.description }}</p>
                <p><strong>Location:</strong> {{ house.location }}</p>
                <p>
                  <strong>Amenities:</strong>
                  {{ house.amenities ? house.amenities.join(", ") : "N/A" }}
                </p>
                <p><strong>Period:</strong> {{ formatPeriod(house.period) }}</p>
              </div>
            </div>
          </div>
        </section>

        <div class="">
          <div v-if="house.photos && house.photos.length">
            <h2 class="">Photos</h2>
            <div class="">
              <div class="" v-for="photo in house.photos" :key="photo">
                <!-- Card partly https://wickedblocks.dev/blocks/sections/card/card-6/ -->
                <div class="flex flex-col overflow-hidden rounded-lg shadow-lg">
                  <div class="flex-shrink-0">
                    <img class="object-cover" :src="photo" alt="House photo" />
                  </div>

                  <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                    <div class="flex-1">
                      <button class="" @click="toggleFavorite">
                        <span class="material-icons">
                          {{ isFavorite ? "favorite" : "favorite_border" }}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="isAuthenticated && !isOwner" class="message-section">
            <div class="pt-6">
              <label for="email" class="block text-sm font-medium text-neutral-600">
                Send message about apartment
              </label>
              <textarea
                class="block w-full px-5 py-3 mt-2 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300 apearance-none autoexpand"
                id="description"
                v-model="newMessage"
                type="text"
                name="description"
                placeholder="Type your message"
              ></textarea>
            </div>

            <div class="flex gap-3 pt-4">
              <button
                class="flex px-5 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 lg:px-10 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                @click="sendMessage"
              >
                <span class="material-icons pr-2">send</span> Send Message
              </button>

              <button
                class="flex px-5 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 lg:px-10 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                @click="copyListingUrl"
              >
                <span class="material-icons pr-2">content_copy</span> Copy Listing URL
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="help is-danger">Loading house details...</p>
      </div>
    </section>
    <audio
      id="notification-sound"
      src="https://www.fesliyanstudios.com/play-mp3/387"
      preload="auto"
    ></audio>
  </div>
</template>

<script>
import api, { BASE_URL } from "@/services/api";
import io from "socket.io-client";

export default {
  name: "HouseDetail",
  data() {
    return {
      house: null,
      isFavorite: false,
      isOwner: false,
      newMessage: "",
      messages: [],
      error: "",
      socket: null,
      userId: localStorage.getItem("userId"),
      isAuthenticated: !!localStorage.getItem("token"),
    };
  },
  computed: {
    backLink() {
      return this.isAuthenticated ? "/dashboard" : "/listings";
    },
    backButtonText() {
      return this.isAuthenticated ? "Back to Dashboard" : "Back to Listings";
    },
  },
  created() {
    this.fetchHouse();
    if (this.isAuthenticated) {
      this.setupSocket();
    }
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    setupSocket() {
      const token = localStorage.getItem("token");
      this.socket = io(BASE_URL, {
        query: { token },
      });

      this.socket.on("connect", () => {
        console.log("Connected to Socket.io");
        if (this.house) {
          this.socket.emit("joinRoom", { conversationId: this.house._id });
        }
      });

      this.socket.on("receiveMessage", (message) => {
        console.log("Received message:", message);
        this.playNotificationSound();
        this.$notify({
          group: "messages",
          title: "New Message",
          text: "You have received a new message.",
          type: "info",
        });
      });
    },
    async fetchHouse() {
      try {
        const id = this.$route.params.id;
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        if (!token) {
          const response = await api.get(`/api/houses/${id}`);
          this.house = response.data;
        } else {
          const response = await api.get(`/api/houses/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.house = response.data;
          this.isOwner = this.house.userId._id === userId;
          this.checkIfFavorite();
        }
      } catch (error) {
        this.error = "Failed to fetch house details";
      }
    },
    async checkIfFavorite() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token provided");
        }
        const response = await api.get("/api/users/favorites", {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.isFavorite = response.data.some(
          (favorite) => favorite._id === this.house._id
        );
      } catch (error) {
        this.error = "Failed to check favorites";
      }
    },
    async toggleFavorite() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token provided");
        }
        const url = `/api/users/favorites/${this.house._id}`;
        const method = this.isFavorite ? "delete" : "post";
        await api({
          method: method,
          url: url,
          headers: { Authorization: `Bearer ${token}` },
        });
        this.isFavorite = !this.isFavorite;
      } catch (error) {
        this.error = `Failed to ${this.isFavorite ? "remove from" : "add to"} favorites`;
      }
    },
    async sendMessage() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          this.$router.push("/login");
          return;
        }
        const response = await api.post(
          `/api/messages/${this.house.userId._id}`,
          {
            content: this.newMessage,
            houseId: this.house._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const newMessage = {
          sender: { _id: this.userId, username: "You" },
          content: this.newMessage,
          _id: response.data.message._id,
        };
        this.newMessage = "";
        this.$notify({
          group: "messages",
          title: "Message sent",
          text: "Your message has been sent successfully",
          type: "success",
        });
        this.notifyReceiver(newMessage);
        // Emit event to notify receiver
        this.socket.emit("sendMessage", {
          conversationId: this.house._id,
          message: newMessage,
        });
      } catch (error) {
        this.error = "Failed to send message";
        this.$notify({
          group: "messages",
          title: "Error",
          text: "Failed to send message",
          type: "error",
        });
      }
    },
    async copyListingUrl() {
      const listingUrl = `${window.location.origin}/house/${this.house._id}`;
      navigator.clipboard
        .writeText(listingUrl)
        .then(() => {
          this.$notify({
            group: "messages",
            title: "Copied",
            text: "Listing URL copied to clipboard",
            type: "success",
          });
        })
        .catch((err) => {
          console.error("Failed to copy listing URL:", err);
        });
    },
    async notifyReceiver(message) {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token provided");
        }
        await api.post(
          "/api/notifications",
          {
            receiverId: this.house.userId._id,
            content: `New message from ${message.sender.username}: ${message.content}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } catch (error) {
        console.error("Failed to send notification:", error);
      }
    },
    playNotificationSound() {
      const sound = document.getElementById("notification-sound");
      sound.play();
    },
    formatPeriod(period) {
      if (!period) return "N/A";
      const options = { year: "numeric", month: "long", day: "numeric" };
      const startDate = new Date(period.start).toLocaleDateString(undefined, options);
      const endDate = new Date(period.end).toLocaleDateString(undefined, options);
      return `${startDate} to ${endDate}`;
    },
  },
};
</script>

<style scoped></style>
