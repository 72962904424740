<template>
  <div class="messages">
    <section class="section">
      <div class="container">
        <h1 class="title has-text-centered">Messages</h1>
        <div v-if="conversations.length">
          <div
            v-for="conversation in sortedConversations"
            :key="conversation._id"
            :class="['box', { unread: hasUnreadMessages(conversation) }]"
            @click="handleConversationClick(conversation)"
          >
            <div class="card-link">
              <div class="card">
                <div class="card-content">
                  <p class="title">{{ getParticipant(conversation.participants) }}</p>
                  <p class="subtitle">{{ conversation.latestMessage }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p v-else class="help is-info">No messages yet.</p>
        <p class="help is-danger" v-if="error">{{ error }}</p>
      </div>
    </section>
    <audio id="notification-sound" src="https://www.fesliyanstudios.com/play-mp3/387" preload="auto"></audio>
  </div>
</template>

<script>
import api, { BASE_URL } from '@/services/api';
import io from 'socket.io-client';

export default {
  name: 'Messages',
  data() {
    return {
      conversations: [],
      error: '',
      socket: null
    };
  },
  created() {
    this.checkToken();
    this.fetchConversations();
    this.setupSocket();
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  computed: {
    sortedConversations() {
      return this.conversations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    }
  },
  methods: {
  checkToken() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.$router.push('/login');
    }
  },
  setupSocket() {
    const token = localStorage.getItem('token');
    this.socket = io(BASE_URL, {
      query: { token }
    });

    this.socket.on('connect', () => {
      console.log('Connected to Socket.io');
    });

    this.socket.on('receiveMessage', (message) => {
      console.log('Received message:', message);
      this.fetchConversations();
      this.$emit('new-message');
      this.playNotificationSound();
    });
  },
  async fetchConversations() {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }
      const response = await api.get('/api/messages', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      this.conversations = response.data;
      console.log('Fetched conversations:', this.conversations);
    } catch (error) {
      this.error = 'Failed to fetch messages';
    }
  },
  getParticipant(participants) {
    const userId = localStorage.getItem('userId');
    const participant = participants.find(p => p._id !== userId);
    return participant ? participant.username : 'Unknown';
  },
  playNotificationSound() {
    const sound = document.getElementById('notification-sound');
    sound.play();
  },
  hasUnreadMessages(conversation) {
    console.log('Checking unread messages for conversation:', conversation);
    return conversation.messages.some(message => !message.read && message.sender !== localStorage.getItem('userId'));
  },
  async markMessagesAsRead(conversationId) {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token provided');
      }

      console.log('Marking messages as read for conversation:', conversationId);

      const response = await api.post('/api/messages/receipts/mark-as-read', { conversationId }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log('Marked messages as read:', response.data);
    } catch (error) {
      console.error('Failed to mark messages as read:', error);
      console.error('Error details:', {
        message: error.message,
        stack: error.stack,
        response: error.response ? error.response.data : 'No response data',
      });
    }
  },
  async handleConversationClick(conversation) {
    try {
      await this.markMessagesAsRead(conversation._id);
      this.$router.push({ name: 'MessageDetail', params: { id: conversation._id } });
    } catch (error) {
      console.error('Error handling conversation click:', error);
    }
  }
}

};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.messages {
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
}

.section {
  padding: 3rem 1.5rem;
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #fff;
}

.card {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  background-color: #2b2b2b;
  margin-bottom: 1rem;
}

.card:hover {
  transform: translateY(-5px);
}

.card-content {
  padding: 1rem;
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.card-link:hover .card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #3273dc;
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #ccc;
}

.unread {
  border-left: 5px solid #ff3860; /* Highlight unread conversations */
}

.help.is-info, .help.is-danger {
  color: #3273dc;
}

@media screen and (max-width: 768px) {
  .columns.is-multiline {
    flex-direction: column;
  }
}
</style>
