<template>
  <div class="edit-house">
    <section class="section">
      <div class="container">
        <h1 class="title">Edit House</h1>
        <form @submit.prevent="updateHouse">
          <div class="field">
            <label class="label">Title</label>
            <div class="control">
              <input class="input" type="text" v-model="house.title" required placeholder="Enter house title">
            </div>
          </div>
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea class="textarea" v-model="house.description" required placeholder="Describe the house"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">Location</label>
            <div class="control">
              <input class="input" type="text" v-model="house.location" required placeholder="Enter location">
            </div>
          </div>
          <div class="field">
            <label class="label">Photos</label>
            <div class="control">
              <input type="file" @change="onFileChange" multiple>
            </div>
            <p class="help">You can upload up to 3 images</p>
            <p class="help is-danger" v-if="files.length > 3">You can only upload up to 3 images.</p>
          </div>
          <div class="field">
            <label class="label">Amenities (comma separated)</label>
            <div class="control">
              <input class="input" type="text" v-model="house.amenities" placeholder="Amenity1, Amenity2, Amenity3...">
            </div>
          </div>
          <div class="field">
            <label class="label">Start Date</label>
            <div class="control">
              <input class="input" type="date" v-model="house.period.start" required>
            </div>
          </div>
          <div class="field">
            <label class="label">End Date</label>
            <div class="control">
              <input class="input" type="date" v-model="house.period.end" required>
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <button class="button is-primary" type="submit" :disabled="files.length > 3">Update House</button>
            </div>
            <div class="control">
              <router-link to="/dashboard?tab=listings" class="button is-link">Back to Listings</router-link>
            </div>
          </div>
        </form>
        <p class="help is-danger" v-if="error">{{ error }}</p>
        <p class="help is-success" v-if="success">{{ success }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import api from '@/services/api';

export default {
  name: 'EditHouse',
  data() {
    return {
      house: {
        title: '',
        description: '',
        location: '',
        photos: [],
        amenities: '',
        period: {
          start: '',
          end: ''
        }
      },
      error: '',
      success: '',
      files: []
    };
  },
  created() {
    this.fetchHouse();
  },
  methods: {
    onFileChange(event) {
      const selectedFiles = Array.from(event.target.files);
      if (selectedFiles.length > 3) {
        this.error = 'You can only upload up to 3 images.';
        this.files = [];
      } else {
        this.files = selectedFiles;
        this.error = '';
      }
    },
    async fetchHouse() {
      try {
        const id = this.$route.params.id;
        const token = localStorage.getItem('token');
        const response = await api.get(`/api/houses/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const house = response.data;
        this.house = {
          ...house,
          photos: house.photos.join(', '),
          amenities: house.amenities.join(', '),
          period: house.period || { start: '', end: '' }
        };
      } catch (error) {
        this.error = 'Failed to fetch house details';
      }
    },
    async uploadImages() {
      const uploadPromises = this.files.map(file => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET);

        return axios.post(`https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload`, formData)
          .then(response => response.data.secure_url);
      });

      return Promise.all(uploadPromises);
    },
    async updateHouse() {
      try {
        const token = localStorage.getItem('token');
        const photoUrls = await this.uploadImages();
        const houseData = {
          ...this.house,
          photos: photoUrls.length > 0 ? photoUrls : this.house.photos.split(',').map(photo => photo.trim()),
          amenities: this.house.amenities.split(',').map(amenity => amenity.trim())
        };

        const id = this.$route.params.id;
        await api.put(`/api/houses/${id}`, houseData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        this.success = 'House updated successfully';
        this.error = '';
        this.$router.push('/dashboard?tab=listings');
      } catch (error) {
        this.error = 'Failed to update house';
        this.success = '';
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.edit-house {
  max-width: 800px;
  margin: 0 auto;
  background-color: #2b2b2b;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
}

.field {
  margin-bottom: 1.5rem;
}

.label {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #fff;
}

.input, .textarea {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 0.75rem;
  background-color: #444;
  color: #fff;
}

.input::placeholder, .textarea::placeholder {
  color: #bbb;
}

.input:focus, .textarea:focus {
  border-color: #3273dc;
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}

.button.is-primary {
  background-color: #3273dc;
  border-color: transparent;
  color: #fff;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
}

.button.is-link {
  background-color: #00d1b2;
  border-color: transparent;
  color: #fff;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
}

.help.is-danger, .help.is-success {
  color: #ff3860;
}

.help.is-info {
  color: #3273dc;
}

@media screen and (max-width: 768px) {
  .edit-house {
    padding: 1rem;
  }

  .field {
    margin-bottom: 1rem;
  }

  .button.is-primary, .button.is-link {
    padding: 0.5rem 1rem;
  }
}
</style>
