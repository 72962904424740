import axios from 'axios';
import router from '@/router'; // Ensure your router is properly imported

export const BASE_URL = window.location.protocol === 'http:' ? 'http://localhost:3000' : process.env.VUE_APP_BASE_API_URL || 'https://switch-cribs-prod-655912bb2657.herokuapp.com'

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Token has expired or user is unauthorized
      localStorage.removeItem('token'); // Clear the token
      router.push('/login'); // Redirect to the login page
    }
    return Promise.reject(error.response ? error.response.data : error);
  }
);

export default api;
