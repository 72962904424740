<template>
  <div class="favorites-list">
    <section class="section">
      <div class="container">
        <div v-if="favorites.length > 0" class="columns is-multiline">
          <div class="column is-one-third" v-for="house in favorites" :key="house._id">
            <router-link :to="'/house/' + house._id" class="card-link">
              <div class="card">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <img :src="house.photos[0]" alt="House photo">
                  </figure>
                </div>
                <div class="card-content">
                  <p class="title">{{ house.title }}</p>
                  <p class="subtitle">{{ house.description }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <p v-else class="help is-info">You have no favorite listings yet.</p>
        <p class="help is-danger" v-if="error">{{ error }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  name: 'FavoritesList',
  data() {
    return {
      favorites: [],
      error: ''
    };
  },
  created() {
    this.fetchFavorites();
  },
  methods: {
    fetchFavorites() {
      const token = localStorage.getItem('token');
      api.get('/api/users/favorites', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        this.favorites = response.data;
      })
      .catch(error => {
        console.log(error);
        this.error = 'Failed to fetch favorite listings';
      });
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

.favorites-list {
  margin-top: 20px;
}

.section {
  padding: 3rem 1.5rem;
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.card-link:hover .card {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.card {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  background-color: #fff;
}

.card:hover {
  transform: translateY(-5px);
}

.card-image img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-content {
  padding: 1.5rem;
}

.title {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #3273dc;
}

.subtitle {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #4a4a4a;
}

.help.is-info {
  color: #3273dc;
}

.help.is-danger {
  color: #ff3860;
}

@media screen and (max-width: 768px) {
  .columns.is-multiline {
    flex-direction: column;
  }

  .card-content {
    padding: 1rem;
  }
}
</style>
