<template>
  <div class="login">
    <!-- from https://wickedblocks.dev/blocks/forms/login/login-4/ -->
    <section class="overflow-hidden">
      <div
        class="flex flex-col justify-center flex-1 px-4 py-12 overflow-hidden sm:px-6 lg:flex-none lg:px-20 xl:px-24"
      >
        <div class="w-full max-w-xl mx-auto lg:w-96">
          <div>
            <a class="text-blue-600 text-medium" href="/">Switch Cribs</a>
            <h2 class="mt-6 text-3xl font-extrabold text-neutral-600">Sign in.</h2>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form @submit.prevent="handleLogin" method="POST" class="space-y-6">
                <div>
                  <label for="email" class="block text-sm font-medium text-neutral-600">
                    Email address
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="email"
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required
                      placeholder="Your Email"
                      class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    />
                  </div>
                </div>

                <div class="space-y-1">
                  <label
                    for="password"
                    class="block text-sm font-medium text-neutral-600"
                  >
                    Password
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="password"
                      id="password"
                      name="password"
                      type="password"
                      autocomplete="current-password"
                      required
                      placeholder="Your Password"
                      class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    />
                  </div>
                </div>
                <div class="mt-0.5">
                  <p class="text-red-600" v-if="error">{{ error }}</p>
                </div>

                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      placeholder="Your password"
                      class="w-4 h-4 text-blue-600 border-gray-200 rounded focus:ring-blue-500"
                    />
                    <label for="remember-me" class="block ml-2 text-sm text-neutral-600">
                      Remember me
                    </label>
                  </div>

                  <div class="text-sm">
                    <a href="#" class="font-medium text-blue-600 hover:text-blue-500">
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    class="flex items-center justify-center w-full px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Sign in
                  </button>
                </div>
              </form>

              <!-- TODO: pin this to the bottom or somewhere closer -->
              <div class="mt-10 pt-10">
                <!-- <a href="/signup"
                  >Don't have an account? <span class="text-blue-600">Sign up</span></a
                > -->
                Don't have an account?
                <router-link class="text-blue-600" to="/signup">Sign Up</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      error: "",
      isLoading: false,
    };
  },
  methods: {
    handleLogin() {
      this.isLoading = true;
      const payload = {
        email: this.email,
        password: this.password,
      };

      api
        .post("/api/auth/login", payload)
        .then((response) => {
          // console.log('login res', response.data);
          localStorage.setItem("token", response.data.token); // Store the token
          localStorage.setItem("userId", response.data.user._id); // Store the user ID
          this.$router.push("/dashboard"); // Redirect to the dashboard
        })
        .catch((error) => {
          // console.log('login err', error);
          this.error =
            error?.error ||
            error?.response?.data?.error ||
            "An error occurred during login.";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
  background-color: #f4f4f4 !important;
  font-family: "Inter", sans-serif;
}

.login {
  padding-top: 50px;
}

.box {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #3273dc;
}

.label {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #4a4a4a;
}

.input {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 0.75rem;
  background-color: #fff; /* Ensure the background is white */
  color: #333; /* Ensure text color is readable */
}

.input::placeholder {
  color: #888; /* Placeholder color */
}

.input:focus {
  border-color: #3273dc;
  box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
}

.button.is-primary {
  background-color: #3273dc;
  border-color: transparent;
  color: #fff;
  font-family: "Inter", sans-serif;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.help.is-danger {
  color: #ff3860;
  text-align: center;
}

.has-text-centered {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .login {
    padding: 1rem;
  }

  .box {
    padding: 1rem;
  }

  .button.is-primary {
    padding: 0.5rem 1rem;
  }
}
</style>
