<template>
  <div class="signup">
    <section class="overflow-hidden">
      <div
        class="flex flex-col justify-center flex-1 px-4 py-12 overflow-hidden sm:px-6 lg:flex-none lg:px-20 xl:px-24"
      >
        <div class="w-full max-w-xl mx-auto lg:w-96">
          <div>
            <a class="text-blue-600 text-medium" href="/">Switch Cribs</a>
            <h2 class="mt-6 text-3xl font-extrabold text-neutral-600">Sign up.</h2>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form @submit.prevent="handleSignup" method="POST" class="space-y-6">
                <div>
                  <label
                    for="first_name"
                    class="block text-sm font-medium text-neutral-600"
                  >
                    First name
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="firstName"
                      name="first_name"
                      type="text"
                      autocomplete="given-name"
                      required
                      placeholder="Enter your first name"
                      class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    />
                  </div>
                </div>

                <div class="space-y-1">
                  <label
                    for="last_name"
                    class="block text-sm font-medium text-neutral-600"
                  >
                    Last name
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="lastName"
                      name="last_name"
                      type="text"
                      autocomplete="family-name"
                      required
                      placeholder="Enter your last name"
                      class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    />
                  </div>
                </div>

                <div class="space-y-1">
                  <label
                    for="username"
                    class="block text-sm font-medium text-neutral-600"
                  >
                    Username
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="username"
                      name="username"
                      type="text"
                      required
                      placeholder="Enter your username"
                      class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    />
                  </div>
                </div>

                <div>
                  <label for="email" class="block text-sm font-medium text-neutral-600">
                    Email address
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="email"
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      required
                      placeholder="Your Email"
                      class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    />
                  </div>
                </div>

                <div class="space-y-1">
                  <label
                    for="password"
                    class="block text-sm font-medium text-neutral-600"
                  >
                    Password
                  </label>
                  <div class="mt-1">
                    <input
                      v-model="password"
                      id="password"
                      name="password"
                      type="password"
                      autocomplete="current-password"
                      required
                      placeholder="Your Password"
                      class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                    />
                  </div>
                </div>
                <div class="mt-0.5">
                  <p class="text-red-600" v-if="error">{{ error }}</p>
                </div>

                <div>
                  <button
                    type="submit"
                    class="flex items-center justify-center w-full px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <span v-if="isLoading" class="spinner"></span>
                    <span v-else>Sign Up</span>
                  </button>
                </div>
              </form>

              <!-- TODO: pin this to the bottom or somewhere closer -->
              <div class="mt-10 pt-10">
                Already have an account?
                <router-link class="text-blue-600" to="/login">Login</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "Signup",
  data() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      error: "",
      isLoading: false,
    };
  },
  methods: {
    handleSignup() {
      this.isLoading = true;
      const payload = {
        firstName: this.firstName,
        lastName: this.lastName,
        username: this.username,
        email: this.email,
        password: this.password,
      };

      api
        .post("/api/auth/signup", payload)
        .then((response) => {
          console.log(response.data);
          this.$router.push("/login"); // Redirect to the login page
        })
        .catch((error) => {
          console.log(error);
          this.error = error.response.data.error || "An error occurred during signup.";
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
