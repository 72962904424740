<template>
  <div class="house-list">
    <section class="section">
      <div class="container">
        <div class="field">
          <router-link to="/add-house" class="button is-primary"
            >Add New House</router-link
          >
        </div>
        <!-- <div v-if="houses.length > 0" class="columns is-multiline">
          <div class="column is-one-third" v-for="house in houses" :key="house._id">
            <div class="card">
              <div class="card-image">
                <figure class="image is-4by3">
                  <img :src="house.photos[0]" alt="House photo" />
                </figure>
              </div>
              <div class="card-content">
                <p class="title">{{ house.title }}</p>
                <p class="subtitle">{{ house.description }}</p>
                <router-link :to="'/edit-house/' + house._id" class="button is-info">Edit</router-link>
                <button @click="confirmDelete(house._id)" class="button is-danger">Delete</button>
              </div>
            </div>
          </div>
        </div> -->
        <!-- New section -->
        <section v-if="houses.length > 0">
          <div class="relative mx-auto max-w-7xl">
            <div class="grid max-w-lg gap-12 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
              <div
                v-for="house in houses"
                :key="house._id"
                class="flex flex-col mb-12 overflow-hidden cursor-pointer"
              >
                <a href="/blog-post">
                  <div class="flex-shrink-0">
                    <img
                      class="object-cover w-full h-48 rounded-lg"
                      :src="house.photos[0] ?? '@/assets/image/neon-placeholder.jpg'"
                      alt="House photo"
                    />
                  </div>
                </a>
                <div class="flex flex-col justify-between flex-1">
                  <div class="flex-1">
                    <a href="#!">
                      <div class="flex pt-6 space-x-1 text-sm text-gray-500">
                        <time datetime="2020-03-10"> Mar 10, 2020 </time>
                        <span aria-hidden="true"> · </span>
                        <span> 4 min read </span>
                      </div>
                    </a>
                    <router-link :to="'/house/' + house._id" class="block mt-2 space-y-6">
                      <h3
                        class="text-2xl font-semibold leading-none tracking-tighter text-neutral-600"
                      >
                        {{ house.title }}
                      </h3>
                      <p class="text-lg font-normal text-gray-500">
                        {{ house.description }}
                      </p>
                    </router-link>

                    <div class="flex items-center gap-3">
                        <router-link :to="'/edit-house/' + house._id" class="p-2 bg-blue-200 rounded-md flex-initial">Edit</router-link>
                        <button @click="confirmDelete(house._id)" class="p-2 bg-red-100 rounded-md flex-initial">Delete</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- // New section -->
        <p v-else class="help is-info">There are no house listings yet.</p>
        <p class="help is-danger" v-if="error">{{ error }}</p>
      </div>
    </section>

    <!-- Delete Confirmation Modal -->
    <!-- <div class="modal" :class="{ 'is-active': showModal, 'hidden': !showModal }">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <p>Are you sure you want to delete this house?</p>
          <div class="buttons">
            <button class="button is-danger" @click="deleteHouse">Yes</button>
            <button class="button" @click="closeModal">No</button>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal"
      ></button>
    </div> -->

    <!-----
      add this code to this very first div:
      fixed inset-0 z-10
    -->
<div :class="{ 'is-active': showModal, 'hidden': !showModal }" class="fixed inset-0 mx-auto z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="flex items-end justify-center min- px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <!--This is the background that overlays when the modal is active. It  has opacity, and that's why the background looks gray.-->
        <!-----
      add this code to this very first div:
      fixed inset-0
    -->
        <div class="transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:" aria-hidden="true"></span>
        <!--Modal panel : This is where you put the pop-up's content, the div on top this comment is the wrapper -->
        <div class="inline-block p-5 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-2xl lg:p-16 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <div>
                <div class="mt-3 text-left sm:mt-5">
                    <h1 class="mb-8 text-2xl font-semibold leading-none tracking-tighter text-neutral-600">Delete listing</h1>
                    <p class="mx-auto text-base leading-relaxed text-gray-500">
                      Are you sure you want to delete this house?
                    </p>
                </div>
            </div>
            <div class="mt-6 sm:flex">
                <div class="mt-3 rounded-lg sm:mt-0">
                    <button @click="closeModal" class="items-center block px-10 py-3.5 text-base font-medium text-center text-blue-600 transition duration-500 ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                      Cancel
                    </button>
                </div>
                <div class="mt-3 rounded-lg sm:mt-0 sm:ml-3">
                    <button @click="deleteHouse" class="items-center block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      Yes
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
  </div>
</template>
<script>
import api from "@/services/api";

export default {
  name: "HouseList",
  data() {
    return {
      houses: [],
      error: "",
      showModal: false,
      houseToDelete: null,
    };
  },
  created() {
    this.checkToken();
    this.fetchHouses();
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/login");
      }
    },
    fetchHouses() {
      const token = localStorage.getItem("token");
      api
        .get("/api/houses", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.houses = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = "Failed to fetch house listings";
        });
    },
    confirmDelete(id) {
      this.houseToDelete = id;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.houseToDelete = null;
    },
    deleteHouse() {
      const token = localStorage.getItem("token");
      api
        .delete(`/api/houses/${this.houseToDelete}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.fetchHouses();
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
          this.error = "Failed to delete house listing";
          this.closeModal();
        });
    },
  },
};
</script>
<style scoped>
/* Modal Styles */
</style>
