<template>
  <div class="font-body">
    <!-- home section -->
    <section class="bg-white mb-20 md:mb-52 xl:mb-72">
      <div class="container max-w-screen-xl mx-auto px-4">
        <nav
          class="flex-wrap lg:flex items-center py-14 xl:relative z-10"
          x-data="{navbarOpen:false}"
        >
          <div class="flex items-center justify-between mb-10 lg:mb-0">
            <img
              src="@/assets/image/SWITCHcribs/transparent-bkgrd-rctngl.png"
              alt="Logo image"
              class="w-52 md:w-80"
            />

            <button
              class="lg:hidden w-10 h-10 ml-auto flex items-center justify-center text-blue-600 border border-blue-600 rounded-md"
              @click="navbarOpen = !navbarOpen"
            >
              <span v-if="!navbarOpen" class="text-blue-600 material-symbols-outlined">
                menu
              </span>
              <span v-else class="text-blue-600 material-symbols-outlined">
                menu_open
              </span>
            </button>
          </div>

          <ul
            class="lg:flex flex-col lg:flex-row lg:items-center lg:mx-auto lg:space-x-8 xl:space-x-16"
            :class="{ hidden: !navbarOpen, flex: navbarOpen }"
          >
            <li
              class="font-semibold text-gray-900 text-lg hover:text-gray-400 transition ease-in-out duration-300 mb-5 lg:mb-0"
            >
              <a href="#">Landing</a>
            </li>

            <li
              class="font-semibold text-gray-900 text-lg hover:text-gray-400 transition ease-in-out duration-300 mb-5 lg:mb-0"
            >
              <a href="#">Pages</a>
            </li>

            <li
              class="font-semibold text-gray-900 text-lg hover:text-gray-400 transition ease-in-out duration-300 mb-5 lg:mb-0"
            >
              <a href="#">Contact</a>
            </li>

            <li
              class="font-semibold text-gray-900 text-lg hover:text-gray-400 transition ease-in-out duration-300 mb-5 lg:mb-0"
            >
              <a href="#">About</a>
            </li>
          </ul>

          <router-link
            :to="'/register'"
            class="px-5 py-3 lg:block border-2 border-blue-600 rounded-lg font-semibold text-blue-600 text-lg hover:bg-blue-600 hover:text-white transition ease-linear duration-500"
            :class="{ hidden: !navbarOpen, flex: navbarOpen }"
            >Sign up</router-link
          >
        </nav>

        <div class="flex items-center justify-center xl:justify-start">
          <div class="mt-28 text-center xl:text-left">
            <h1
              class="font-semibold text-4xl md:text-6xl lg:text-7xl text-gray-900 leading-normal mb-6"
            >
              The smart way <br />
              to travel
            </h1>

            <p class="font-normal text-xl text-gray-400 leading-relaxed mb-12">
              Tired of expensive hotels and vacation rentals? <br />
              Swap your home and save money while exploring new destinations
            </p>

            <button
              class="px-6 py-4 bg-blue-600 text-white font-semibold text-lg rounded-xl hover:bg-blue-900 transition ease-in-out duration-500"
            >
              Contact us
            </button>
          </div>

          <div class="hidden xl:block xl:absolute z-0 top-0 right-0">
            <img src="@/assets/image/home-img.png" alt="Home img" />
          </div>
        </div>
      </div>
      <!-- container.// -->
    </section>
    <!-- home section //nd -->

    <!-- feature section -->
    <section class="bg-white py-10 md:py-16 xl:relative">
      <div class="container max-w-screen-xl mx-auto px-4">
        <div class="flex flex-col xl:flex-row justify-end">
          <div class="hidden xl:block xl:absolute left-0 bottom-0 w-full">
            <img src="@/assets/image/feature-img.png" alt="Feature img" />
          </div>

          <div class="">
            <h1
              class="font-semibold text-gray-900 text-xl md:text-4xl text-center leading-normal mb-6"
            >
              Why Choose Switch Cribs?
              <!-- <br /> us? -->
            </h1>

            <p class="font-normal text-gray-400 text-md md:text-xl text-center mb-16">
              We provide a wide of selection of home types for you and your <br />
              family and are free to choose a home model
            </p>

            <div
              class="flex flex-col md:flex-row justify-center xl:justify-start space-x-4 mb-20"
            >
              <div
                class="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0"
              >
                <span class="text-blue-900 material-symbols-outlined">task_alt</span>
              </div>

              <div class="text-center md:text-left">
                <h4 class="font-semibold text-gray-900 text-2xl mb-2">
                  Save Money, Obviously!
                </h4>
                <p class="font-normal text-gray-400 text-xl leading-relaxed">
                  Cut travel costs by swapping homes instead of booking <br />
                  expensive hotels or rentals.
                </p>
              </div>
            </div>

            <div
              class="flex flex-col md:flex-row justify-center xl:justify-start space-x-4 mb-20"
            >
              <div
                class="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0"
              >
                <span class="text-blue-900 material-symbols-outlined"> lock </span>
              </div>

              <div class="text-center md:text-left">
                <h4 class="font-semibold text-gray-900 text-2xl mb-2">
                  Comfort &amp; Convenience
                </h4>
                <p class="font-normal text-gray-400 text-xl leading-relaxed">
                  Stay in a fully equipped home <br />
                  with all the amenities you need.
                </p>
              </div>
            </div>

            <div
              class="flex flex-col md:flex-row justify-center xl:justify-start space-x-4"
            >
              <div
                class="px-8 h-20 mx-auto md:mx-0 bg-gray-200 rounded-lg flex items-center justify-center mb-5 md:mb-0"
              >
                <span class="text-blue-900 material-symbols-outlined"> credit_card </span>
              </div>

              <div class="text-center md:text-left">
                <h4 class="font-semibold text-gray-900 text-2xl mb-2">Global Reach</h4>
                <p class="font-normal text-gray-400 text-xl leading-relaxed">
                  Join a community of home swappers <br />
                  from around the world.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- container.// -->
    </section>
    <!-- feature section //end -->

    <!-- gallery section -->
    <section class="bg-white py-10 md:py-16">
      <div class="container max-w-screen-xl mx-auto px-4">
        <h1 class="font-semibold text-gray-900 text-4xl text-center mb-10">
          Our Gallery
        </h1>

        <div
          class="hidden md:block flex items-center text-center space-x-10 lg:space-x-20 mb-12"
        >
          <a
            href="#"
            class="px-6 py-2 bg-blue-800 text-white font-semibold text-xl rounded-lg hover:bg-blue-600 transition ease-in-out duration-500"
            >All</a
          >
          <a
            href="#"
            class="px-6 py-2 text-gray-900 font-normal text-xl rounded-lg hover:bg-gray-200 hover:text-gray-400 transition ease-in-out duration-500"
            >Exterior</a
          >
          <a
            href="#"
            class="px-6 py-2 text-gray-900 font-normal text-xl rounded-lg hover:bg-gray-200 hover:text-gray-400 transition ease-in-out duration-500"
            >Interior</a
          >
          <a
            href="#"
            class="px-6 py-2 text-gray-900 font-normal text-xl rounded-lg hover:bg-gray-200 hover:text-gray-400 transition ease-in-out duration-500"
            >Building</a
          >
        </div>

        <div class="flex space-x-4 md:space-x-6 lg:space-x-8">
          <div>
            <img
              src="@/assets/image/gallery-1.png"
              alt="image"
              class="mb-4 md:mb-6 lg:mb-8 hover:opacity-75 transition ease-in-out duration-500"
            />
            <img
              src="@/assets/image/gallery-4.png"
              alt="image"
              class="hover:opacity-75 transition ease-in-out duration-500"
            />
          </div>

          <div>
            <img
              src="@/assets/image/gallery-2.png"
              alt="image"
              class="mb-4 md:mb-6 lg:mb-8 hover:opacity-75 transition ease-in-out duration-500"
            />
            <img
              src="@/assets/image/gallery-5.png"
              alt="image"
              class="mb-3 md:mb-6 lg:mb-8 hover:opacity-75 transition ease-in-out duration-500"
            />
            <img
              src="@/assets/image/gallery-6.png"
              alt="image"
              class="hover:opacity-75 transition ease-in-out duration-500"
            />
          </div>

          <div>
            <img
              src="@/assets/image/gallery-3.png"
              alt="image"
              class="mb-4 md:mb-6 lg:mb-8 hover:opacity-75 transition ease-in-out duration-500"
            />
            <img
              src="@/assets/image/gallery-7.png"
              alt="image"
              class="hover:opacity-75 transition ease-in-out duration-500"
            />
          </div>
        </div>
      </div>
      <!-- container.// -->
    </section>
    <!-- gallery section //end -->

    <!-- testimoni section -->
    <section class="bg-white py-10 md:py-16">
      <div class="container max-w-screen-xl mx-auto px-4 xl:relative">
        <p
          class="font-normal text-gray-400 text-lg md:text-xl text-center uppercase mb-6"
        >
          Testimonial
        </p>

        <h1
          class="font-semibold text-gray-900 text-2xl md:text-4xl text-center leading-normal mb-14"
        >
          What People Say <br />
          About Switch Cribs
        </h1>

        <div class="hidden xl:block xl:absolute top-0">
          <img src="@/assets/image/testimoni-1.png" alt="Image" />
        </div>

        <div class="hidden xl:block xl:absolute top-32">
          <img src="@/assets/image/testimoni-2.png" alt="Image" />
        </div>

        <div
          class="flex flex-col md:flex-row md:items-center justify-center md:space-x-8 lg:space-x-12 mb-10 md:mb-20"
        >
          <div class="bg-gray-100 rounded-lg mb-10 md:mb-0">
            <img src="@/assets/image/testimoni-3.png" alt="Image" class="mx-8 my-8" />

            <div class="flex items-center gap-5 mx-8">
              <!-- TODO: should be "filled" -->
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
            </div>

            <p class="font-normal text-sm lg:text-md text-gray-400 mx-8 my-8">
              I recommend anyone to buy house on <br />
              Switch Cribs. I received great customer service <br />
              from the specialists who helped me.
            </p>

            <h3
              class="font-semibold text-gray-900 text-xl md:text-2xl lg:text-3xl mx-8 mb-8"
            >
              Brooklyn Simmons
            </h3>
          </div>

          <div class="bg-gray-100 rounded-lg">
            <img src="@/assets/image/testimoni-4.png" alt="Image" class="mx-8 my-8" />

            <div class="flex items-center gap-5 mx-8">
              <!-- TODO: should be "filled" -->
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
              <span class="text-yellow-500 material-symbols-outlined">star</span>
            </div>

            <p class="font-normal text-sm lg:text-md text-gray-400 mx-8 my-8">
              Switch Cribs is the best property agent in the <br />
              world. I received great customer service <br />
              from the Switch Cribs agent
            </p>

            <h3
              class="font-semibold text-gray-900 text-xl md:text-2xl lg:text-3xl mx-8 mb-8"
            >
              Ralph Edwards
            </h3>
          </div>
        </div>
      </div>
      <!-- container.// -->
    </section>
    <!-- testimoni section //end -->

    <!-- book section -->
    <section class="bg-white py-10 md:py-16">
      <div class="container max-w-screen-xl mx-auto px-4 xl:relative">
        <div
          class="bg-blue-800 flex flex-col lg:flex-row items-center justify-evenly py-14 rounded-3xl"
        >
          <div class="text-center lg:text-left mb-10 lg:mb-0">
            <h1
              class="font-semibold text-white text-4xl md:text-5xl lg:text-7xl leading-normal mb-4"
            >
              Talk to us <br />
              to discuss
            </h1>

            <p class="font-normal text-white text-md md:text-xl">
              Need more time to discuss? Won’t worry, we are <br />
              ready to help you. You can fill in the column on the <br />
              right to book a meeting with us. Totally free.
            </p>
          </div>

          <div class="hidden xl:block xl:absolute right-0">
            <img src="@/assets/image/book.png" alt="Image" />
          </div>

          <div class="hidden md:block bg-white xl:relative px-6 py-3 rounded-3xl">
            <div class="py-3">
              <h3 class="font-semibold text-gray-900 text-3xl">Book a meeting</h3>
            </div>

            <div class="py-3">
              <input
                type="text"
                placeholder="Full Name"
                class="px-4 py-4 w-96 bg-gray-100 placeholder-gray-400 rounded-xl outline-none"
              />
            </div>

            <div class="py-3">
              <input
                type="text"
                placeholder="Email"
                class="px-4 py-4 w-96 bg-gray-100 placeholder-gray-400 rounded-xl outline-none"
              />
            </div>

            <div class="py-3 relative">
              <input
                type="text"
                placeholder="Date"
                class="px-4 py-4 w-96 bg-gray-100 font-normal text-lg placeholder-gray-400 rounded-xl outline-none"
              />

              <div
                class="absolute inset-y-0 left-80 ml-6 flex items-center text-xl text-gray-600"
              >
                <span class="text-blue-600 material-symbols-outlined"> event </span>
              </div>
            </div>

            <div class="py-3 relative">
              <input
                type="text"
                placeholder="Virtual Meeting"
                class="px-4 py-4 w-96 bg-gray-100 placeholder-gray-400 rounded-xl outline-none"
              />

              <div
                class="absolute inset-y-0 left-80 ml-6 flex items-center text-xl text-gray-600"
              >
                <span class="text-blue-600 material-symbols-outlined"> groups_2 </span>
              </div>
            </div>

            <div class="py-3">
              <button
                class="w-full py-4 font-semibold text-lg text-white bg-blue-600 rounded-xl hover:bg-blue-900 transition ease-in-out duration-500"
              >
                Booking
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- container.// -->
    </section>
    <!-- book section //end -->

    <!-- footer -->
    <footer class="bg-white py-10 md:py-16">
      <div class="container max-w-screen-xl mx-auto px-4">
        <div class="flex flex-col lg:flex-row justify-between">
          <div class="text-center lg:text-left mb-10 lg:mb-0">
            <div class="flex justify-center lg:justify-start mb-5">
              <img
                class="w-52 md:w-80"
                src="@/assets/image/SWITCHcribs/transparent-bkgrd-rctngl.png"
                alt="Image"
              />
            </div>

            <p class="font-light text-gray-400 text-xl mb-10">
              Get your dream house with <br />
              Switch Cribs
            </p>

            <div class="flex items-center justify-center lg:justify-start space-x-5">
              <a
                href="#"
                class="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-blue-800 hover:text-white transition ease-in-out duration-500"
              >
                <i data-feather="facebook"></i>
              </a>

              <a
                href="#"
                class="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-blue-800 hover:text-white transition ease-in-out duration-500"
              >
                <i data-feather="twitter"></i>
              </a>

              <a
                href="#"
                class="px-3 py-3 bg-gray-200 text-gray-700 rounded-full hover:bg-blue-800 hover:text-white transition ease-in-out duration-500"
              >
                <i data-feather="linkedin"></i>
              </a>
            </div>
          </div>

          <div class="text-center lg:text-left mb-10 lg:mb-0">
            <h4 class="font-semibold text-gray-900 text-2xl mb-6">Sitemap</h4>

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Home</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Features</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Gallery</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Testimoni</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Book a meeting</a
            >
          </div>

          <div class="text-center lg:text-left mb-10 lg:mb-0">
            <h4 class="font-semibold text-gray-900 text-2xl mb-6">Landing</h4>

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Mobile App</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Property</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Personal Website</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Web Developer</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Online Course</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Donation</a
            >
          </div>

          <div class="text-center lg:text-left">
            <h4 class="font-semibold text-gray-900 text-2xl mb-6">Utility</h4>

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >FAQ</a
            >

            <a
              href="#"
              class="block font-light text-gray-400 text-xl mb-6 hover:text-gray-800 transition ease-in-out duration-300"
              >Terms & Conditions</a
            >
          </div>
        </div>
      </div>
      <!-- container.// -->
    </footer>
    <!-- footer //end -->
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "NewHome",
  data() {
    return {
      features: [
        {
          title: "Save Money",
          subtitle:
            "Cut travel costs by swapping homes instead of booking expensive hotels or rentals.",
        },
        {
          title: "Comfort & Convenience",
          subtitle: "Stay in a fully equipped home with all the amenities you need.",
        },
        {
          title: "Global Reach",
          subtitle: "Join a community of home swappers from around the world.",
        },
      ],
      testimonials: [
        {
          name: "Francine Simmons",
          feedback: "Home Swap made my vacation so much better! Seamless experience.",
        },
        {
          name: "Babatunde Emmanual",
          feedback: "Highly recommend for anyone looking to swap homes. Very secure.",
        },
        {
          name: "Samuel Nguyen",
          feedback: "Excellent service and great customer support!",
        },
      ],
      faqs: [
        {
          question: "How do I start swapping homes?",
          answer: "Sign up, list your home, and start exploring swap opportunities.",
        },
        {
          question: "Is my information safe?",
          answer: "Yes, we use advanced security measures to protect your data.",
        },
        {
          question: "Can I swap homes internationally?",
          answer: "Absolutely! We have a global community of home swappers.",
        },
        {
          question: "What happens if there is damage to my home?",
          answer:
            "We provide an optional insurance plan to cover potential damages during the swap.",
        },
        {
          question: "How do you ensure the security of my personal information?",
          answer:
            "We use state-of-the-art encryption and security measures to protect your data.",
        },
        {
          question: "Can I review and rate my swap experience?",
          answer:
            "Yes, after the swap, both parties can review and rate their experience.",
        },
      ],
      listings: [],
      navbarOpen: false,
      newsletterEmail: "",
      error: "",
      success: "",
    };
  },
  computed: {
    backgroundImageStyle() {
      return {
        backgroundImage: `url(${require("@/assets/hero-image.jpg")})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
    },
  },
  created() {
    this.loadExternalScripts();
    this.fetchListings();
  },
  methods: {
    fetchListings() {
      const token = localStorage.getItem("token");
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      api
        .get("/api/houses/all", { headers })
        .then((response) => {
          this.listings = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    subscribeNewsletter() {
      if (!this.newsletterEmail) {
        this.error = "Please enter a valid email address.";
        return;
      }
      // Here, you would typically send the email to your backend API.
      // For demonstration, we'll just show a success message.
      this.success = "Subscribed successfully!";
      this.error = "";
      this.newsletterEmail = "";
    },
    loadExternalScripts() {
      const script1 = document.createElement("script");

      script1.async = true;
      script1.crossOrigin = "anonymous";
      script1.src =
        "https://cdnjs.cloudflare.com/ajax/libs/feather-icons/4.28.0/feather.min.js";
      script1.integrity =
        "sha512-7x3zila4t2qNycrtZ31HO0NnJr8kg2VI67YLoRSyi9hGhRN66FHYWr7Axa9Y1J9tGYHVBPqIjSE1ogHrJTz51g==";
      script1.referrerPolicy = "no-referrer";
      document.head.appendChild(script1);

      // -

      const script2 = document.createElement("script");
      script2.src = "https://unpkg.com/alpinejs@3.x.x/dist/cdn.min.js";
      script2.defer = true;
    },
  },
};
</script>

<style scoped></style>
