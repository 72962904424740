import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import 'bulma/css/bulma.css'; // TODO: needs to be dis-used
import './style.css'; // tailwind (to be the used default)
import Notifications from 'vue-notification';


Vue.config.productionTip = false;
Vue.use(Notifications);


new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
