<template>
  <div class="dashboard">
    <!-- <section class="section">
      <div class="container">
        <div class="level">
          <div class="level-left">
            <h1 class="title">Hi {{ user.firstName }}</h1>
          </div>
          <div class="level-right">
            <button class="button is-info" @click="showUserGuide">User Guide</button>
            <button class="button is-danger" @click="logout">Logout</button>
          </div>
        </div>
        <div class="tabs is-boxed is-fullwidth">
          <ul>
            <li :class="{ 'is-active': activeTab === 'search' }">
              <a @click="setActiveTab('search')">
                <span class="material-icons">search</span> Search Listings
              </a>
            </li>
            <li :class="{ 'is-active': activeTab === 'listings' }">
              <a @click="setActiveTab('listings')">
                <span class="material-icons">view_list</span> Your Listings
              </a>
            </li>
            <li :class="{ 'is-active': activeTab === 'favorites' }">
              <a @click="setActiveTab('favorites')">
                <span class="material-icons">favorite</span> Favorites
              </a>
            </li>
            <li :class="{ 'is-active': activeTab === 'profile' }">
              <a @click="setActiveTab('profile')">
                <span class="material-icons">person</span> Profile
              </a>
            </li>
            <li :class="{ 'is-active': activeTab === 'messages' }">
              <a @click="setActiveTab('messages')">
                <span class="material-icons">message</span> Messages
                <span v-if="newMessagesCount" class="tag is-danger">{{
                  newMessagesCount
                }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div v-if="activeTab === 'search'">
          <SearchListings />
        </div>
        <div v-if="activeTab === 'listings'">
          <HouseList />
        </div>
        <div v-if="activeTab === 'favorites'">
          <FavoritesList />
        </div>
        <div v-if="activeTab === 'profile'">
          <Profile />
        </div>
        <div v-if="activeTab === 'messages'">
          <Messages @new-message="incrementNewMessagesCount" />
        </div>
      </div>
    </section> -->

    <!-- <div class="modal" :class="{ 'is-active': isUserGuideVisible }">
      <div class="modal-background" @click="hideUserGuide"></div>
      <div class="modal-content">
        <div class="box user-guide-box">
          <h1 class="title has-text-centered">User Guide</h1>
          <p class="subtitle has-text-centered">
            Welcome to Home Swap! Here are the main features you can use:
          </p>
          <div class="content">
            <ul>
              <li><strong>Search Listings:</strong> Browse and find homes to swap.</li>
              <li><strong>Your Listings:</strong> Manage your own home listings.</li>
              <li>
                <strong>Favorites:</strong> View homes you have marked as favorites.
              </li>
              <li><strong>Profile:</strong> Update your personal information.</li>
              <li><strong>Messages:</strong> Communicate with other users.</li>
            </ul>
          </div>
          <button class="button is-link is-fullwidth" @click="hideUserGuide">
            Got it!
          </button>
        </div>
      </div>
      <button class="modal-close is-large" @click="hideUserGuide"></button>
    </div> -->

    <!-- new section -->
    <div class="flex bg-white">
      <div class="hidden md:flex md:flex-shrink-0">
        <div class="h-screen flex flex-col w-64">
          <div
            class="flex flex-col flex-grow pt-5 overflow-y-auto bg-white border-r border-gray-50"
          >
            <div class="flex flex-col items-center flex-shrink-0 px-4">
              <a class="px-8 text-left focus:outline-none" href="/groups/sidebar/">
                <h2
                  class="block p-2 text-xl font-medium tracking-tighter text-gray-900 transition duration-500 ease-in-out transform cursor-pointer hover:text-gray-900"
                >
                  Switch Cribs
                </h2>
              </a>
              <button class="hidden rounded-lg focus:outline-none focus:shadow-outline">
                <svg fill="currentColor" viewBox="0 0 20 20" class="w-6 h-6">
                  <path
                    fill-rule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div class="flex flex-col flex-grow px-4 mt-5 overflow-y-auto">
              <nav class="flex-1 space-y-1 bg-white">
                <ul>
                  <li>
                    <a
                      @click="setActiveTab('search')"
                      class="inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 ease-in-out transform rounded-lg bg-gray-50 focus:shadow-outline hover:bg-gray-50 cursor-pointer"
                    >
                      <span class="material-symbols-outlined">analytics</span>
                      <span class="ml-4">Home</span>
                    </a>
                  </li>
                  <li>
                    <a
                      @click="setActiveTab('listings')"
                      class="inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline hover:bg-gray-50 cursor-pointer"
                    >
                      <span class="material-symbols-outlined">chat</span>
                      <span class="ml-4">Listing</span>
                    </a>
                  </li>
                  <li>
                    <a
                      @click="setActiveTab('favorites')"
                      class="inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline hover:bg-gray-50 cursor-pointer"
                    >
                      <span class="material-symbols-outlined">person</span>
                      <span class="ml-4">Favorites</span>
                    </a>
                  </li>
                  <li>
                    <a
                      @click="setActiveTab('profile')"
                      class="inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline hover:bg-gray-50 cursor-pointer"
                    >
                      <span class="material-symbols-outlined">settings</span>
                      <span class="ml-4">Profile</span>
                    </a>
                  </li>
                </ul>
                <p class="px-4 pt-4 font-medium text-gray-900 uppercase">Shortcuts</p>
                <ul>
                  <li>
                    <a
                      @click="setActiveTab('messages')"
                      class="inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline hover:bg-gray-50 cursor-pointer"
                    >
                      <span class="material-symbols-outlined">task</span>
                      <span class="ml-4">Messages</span>
                    </a>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      @click="logout"
                      class="inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 ease-in-out transform rounded-lg focus:shadow-outline hover:bg-gray-50 cursor-pointer"
                    >
                      <span class="material-symbols-outlined">task</span>
                      <span class="ml-4">Logout</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="flex flex-shrink-0 p-4 px-4 bg-gray-50">
              <a href="/" class="flex-shrink-0 block w-full group">
                <div class="flex items-center">
                  <div>
                    <!-- TODO: logo image needs thicker lines -->
                    <img
                      class="inline-block rounded-full h-9 w-9"
                      src="@/assets/image/SWITCHcribs/sqr-logo-img.png"
                      alt=""
                    />
                  </div>
                  <div class="ml-3">
                    <p class="text-sm font-medium text-gray-900">Switch Cribs</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col grow">
        <main class="relative overflow-y-auto h-screen">
          <div class="py-6">
            <!-- <div class="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                    <h1 class="text-lg text-neutral-600">Here is where you put your stuff</h1>
                </div> -->
            <div class="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
              <!-- Put your content here -->
              <div class="py-4">
                <div v-if="activeTab === 'search'">
                  <SearchListings />
                </div>
                <div v-if="activeTab === 'listings'">
                  <HouseList />
                </div>
                <div v-if="activeTab === 'favorites'">
                  <FavoritesList />
                </div>
                <div v-if="activeTab === 'profile'">
                  <Profile />
                </div>
                <div v-if="activeTab === 'messages'">
                  <Messages @new-message="incrementNewMessagesCount" />
                </div>
              </div>
              <!-- Do not cross the closing tag underneath this comment-->
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import HouseList from "@/components/HouseList.vue";
import SearchListings from "@/components/SearchListings.vue";
import Profile from "@/components/Profile.vue";
import FavoritesList from "@/components/FavoritesList.vue";
import Messages from "@/components/Messages.vue";
import api from "@/services/api";

export default {
  name: "Dashboard",
  components: {
    HouseList,
    SearchListings,
    Profile,
    FavoritesList,
    Messages,
  },
  data() {
    return {
      activeTab: "search",
      user: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        profilePicture: "",
      },
      newMessagesCount: 0,
      isUserGuideVisible: false,
    };
  },
  created() {
    this.checkToken();
    this.fetchProfile();
    const queryTab = this.$route.query.tab;
    if (queryTab) {
      this.activeTab = queryTab;
    }
    this.checkUserGuide();
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/login");
      }
    },
    fetchProfile() {
      const token = localStorage.getItem("token");
      api
        .get("/api/users/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          console.log(error);
          if (error.status === 401) {
            this.$router.push("/login");
          }
        });
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    incrementNewMessagesCount() {
      this.newMessagesCount++;
    },
    showUserGuide() {
      this.isUserGuideVisible = true;
    },
    hideUserGuide() {
      this.isUserGuideVisible = false;
      localStorage.setItem("userGuideSeen", "true");
    },
    checkUserGuide() {
      const userGuideSeen = localStorage.getItem("userGuideSeen");
      if (!userGuideSeen) {
        this.showUserGuide();
      }
    },
  },
};
</script>

<style scoped></style>
