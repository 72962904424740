<template>
  <div class="message-detail">
    <section class="section">
      <div class="container" v-if="conversation">
        <h1 class="title">
          Conversation with {{ getParticipant(conversation.participants) }} (House:
          {{
            conversation.house ? conversation.house.title : "House details not available"
          }})
        </h1>
        <div class="content">
          <div
            v-for="message in conversation.messages"
            :key="message._id"
            :class="getMessageClass(message)"
          >
            <p>
              <strong>{{
                message.sender._id === userId ? "You" : message.sender.username
              }}</strong>
            </p>
            <p
              v-html="
                formatMessageContent(message.content, message.sender._id === userId)
              "
            ></p>
          </div>
          <div class="field">
            <label class="label">New Message</label>
            <div class="control">
              <textarea class="textarea" v-model="newMessage"></textarea>
            </div>
          </div>
          <div class="control">
            <button class="button is-primary" @click="sendMessage">Send</button>
          </div>
        </div>
        <router-link
          :to="{ name: 'Dashboard', query: { tab: 'messages' } }"
          class="button is-link back-button"
          @click="resetConversation"
          >Back to Messages</router-link
        >
      </div>
      <div v-else>
        <p class="help is-danger">Loading conversation...</p>
      </div>
    </section>
    <button class="scroll-button scroll-top" @click="scrollToTop">
      <span class="material-icons">arrow_upward</span>
    </button>
    <button class="scroll-button scroll-bottom" @click="scrollToBottom">
      <span class="material-icons">arrow_downward</span>
    </button>
    <audio
      id="notification-sound"
      src="https://www.fesliyanstudios.com/play-mp3/387"
      preload="auto"
    ></audio>
  </div>
</template>

<script>
import api, { BASE_URL } from "@/services/api";
import io from "socket.io-client";

export default {
  name: "MessageDetail",
  data() {
    return {
      conversation: null,
      newMessage: "",
      error: "",
      socket: null,
      userId: localStorage.getItem("userId"),
    };
  },
  created() {
    this.checkToken();
    this.fetchConversation();
    this.setupSocket();
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/login");
      }
    },
    setupSocket() {
      const token = localStorage.getItem("token");
      this.socket = io(BASE_URL, {
        query: { token },
      });

      this.socket.on("connect", () => {
        console.log("Connected to Socket.io");
        this.socket.emit("joinRoom", { conversationId: this.$route.params.id });
      });

      this.socket.on("receiveMessage", (message) => {
        console.log("Received message:", message);
        if (
          this.conversation.messages.findIndex((m) => m._id === message.message._id) ===
          -1
        ) {
          this.conversation.messages.push(message.message);
          this.playNotificationSound();
          this.scrollToBottom();
        }
      });
    },
    async fetchConversation() {
      try {
        const id = this.$route.params.id;
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token provided");
        }
        const response = await api.get(`/api/messages/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.conversation = response.data;
        this.conversation.messages.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        this.markMessagesAsRead(); // Call the method to mark messages as read
        this.scrollToBottom();
      } catch (error) {
        this.error = "Failed to fetch conversation";
      }
    },
    async sendMessage() {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          this.$router.push("/login");
          return;
        }
        const response = await api.post(
          `/api/messages/${
            this.conversation.participants.find((p) => p._id !== this.userId)._id
          }`,
          {
            content: this.newMessage,
            houseId: this.conversation.house._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.newMessage = "";
        this.scrollToBottom();
      } catch (error) {
        this.error = "Failed to send message";
      }
    },
    getParticipant(participants) {
      const participant = participants.find((p) => p._id !== this.userId);
      return participant ? participant.username : "Unknown";
    },
    async playNotificationSound() {
      const sound = document.getElementById("notification-sound");
      if (sound) {
        try {
          await sound.play();
        } catch (error) {
          console.error("Failed to play notification sound:", error);
        }
      }
    },
    resetConversation() {
      this.conversation = null;
    },
    getMessageClass(message) {
      return {
        box: true,
        sent: message.sender._id === this.userId,
        received: message.sender._id !== this.userId,
      };
    },
    async markMessagesAsRead() {
      try {
        const unreadMessageIds = this.conversation.messages
          .filter((m) => !m.read && m.sender._id !== this.userId)
          .map((m) => m.sender._id);

        console.log("Unread message IDs:", unreadMessageIds); // Log the message IDs

        if (unreadMessageIds.length > 0) {
          const token = localStorage.getItem("token");
          if (!token) {
            throw new Error("No token provided");
          }
          const response = await api.post(
            "/api/messages/receipts/mark-as-read",
            { messageIds: unreadMessageIds },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("Mark as read response:", response.data); // Log the successful response
          // Update local state to reflect messages as read
          this.conversation.messages.forEach((m) => {
            if (unreadMessageIds.includes(m._id)) {
              m.read = true;
            }
          });
        }
      } catch (error) {
        console.error(
          "Failed to mark messages as read:",
          error.response ? error.response.data : error.message
        );
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollToBottom() {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    },
    formatMessageContent(content, isSender) {
      const linkColor = isSender ? "#00FFFF" : "#FFD700";
      return content.replace(
        /(https?:\/\/[^\s]+)/g,
        `<a href="$1" target="_blank" class="message-link" style="color: ${linkColor};">$1</a>`
      );
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

.message-detail {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
}
.box {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff; /* Ensure text color is white for better readability */
  word-wrap: break-word; /* Ensure long URLs wrap correctly */
}
.sent {
  background-color: #007bff; /* Blue for sent messages */
  text-align: right;
}
.received {
  background-color: #6c757d; /* Gray for received messages */
}
.scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #3273dc;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.scroll-top {
  bottom: 70px; /* Position above the scroll to bottom button */
}
.scroll-bottom {
  bottom: 20px;
}
.scroll-button .material-icons {
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .message-detail {
    padding: 1rem;
  }
  .scroll-button {
    bottom: 15px;
    right: 15px;
  }
  .scroll-top {
    bottom: 65px;
  }
}
</style>
