<template>
  <div class="search-listings">
    <!-- <div class="field">
      <label class="label">Search Listings</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="searchQuery"
          @input="searchListings"
          placeholder="Search by city or description"
        />
      </div>
    </div> -->

    <div>
      <label class="sr-only" for="search-input">Search listings</label>
      <input
        v-model="searchQuery"
        @input="searchListings"
        class="w-full rounded-lg border-2 border-gray-200 p-3 text-sm"
        placeholder="Search listings by city or description"
        type="text"
        id="search-input"
      />
    </div>
    <!-- <div v-if="filteredHouses.length > 0" class="columns is-multiline">
      <div class="column is-one-third" v-for="house in filteredHouses" :key="house._id">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <router-link :to="'/house/' + house._id">
                <img :src="house.photos[0]" alt="House photo" />
              </router-link>
            </figure>
          </div>
          <div class="card-content">
            <p class="title">
              <router-link :to="'/house/' + house._id">{{ house.title }}</router-link>
            </p>
            <p class="subtitle">{{ house.description }}</p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- New section -->
    <section v-if="filteredHouses.length > 0">
      <div class="relative mx-auto max-w-7xl">
        <div class="grid max-w-lg gap-12 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
          <div
            v-for="house in filteredHouses"
            :key="house._id"
            class="flex flex-col mb-12 overflow-hidden cursor-pointer"
          >
            <router-link :to="'/house/' + house._id">
              <div class="flex-shrink-0">
                <img
                  class="object-cover w-full h-48 rounded-lg"
                  :src="house.photos[0] ?? '@/assets/image/neon-placeholder.jpg'"
                  alt="House photo"
                />
              </div>
            </router-link>
            <div class="flex flex-col justify-between flex-1">
              <div class="flex-1">
                <a href="#!">
                  <div class="flex pt-6 space-x-1 text-sm text-gray-500">
                    <time datetime="2020-03-10"> Mar 10, 2020 </time>
                    <span aria-hidden="true"> · </span>
                    <span> 4 min read </span>
                  </div>
                </a>
                <router-link :to="'/house/' + house._id" class="block mt-2 space-y-6">
                  <h3
                    class="text-2xl font-semibold leading-none tracking-tighter text-neutral-600"
                  >
                    {{ house.title }}
                  </h3>
                  <p class="text-lg font-normal text-gray-500">{{ house.description }}</p>
                </router-link>
              </div>
            </div>
          </div>

          <!-- <div class="flex flex-col mb-12 overflow-hidden cursor-pointer">
            <a href="/blog-post">
              <div class="flex-shrink-0">
                <img class="object-cover w-full h-48 rounded-lg" src="@/assets/image/neon-placeholder.jpg" alt="">
              </div>
            </a>
            <div class="flex flex-col justify-between flex-1">
              <a href="/blog-post"></a>
              <div class="flex-1">
                <a href="/blog-post">
                  <div class="flex pt-6 space-x-1 text-sm text-gray-500">
                    <time datetime="2020-03-10"> Mar 10, 2020 </time>
                    <span aria-hidden="true"> · </span>
                    <span> 4 min read </span>
                  </div>
                </a>
                <a href="#" class="block mt-2 space-y-6">
                  <h3 class="text-2xl font-semibold leading-none tracking-tighter text-neutral-600">Typography on app.</h3>
                  <p class="text-lg font-normal text-gray-500">Filling text so you can see how it looks like with text. Did I said text?</p>
                </a>
              </div>
            </div>
          </div>

          <div class="flex flex-col mb-12 overflow-hidden cursor-pointer">
            <a href="/blog-post">
              <div class="flex-shrink-0">
                <img class="object-cover w-full h-48 rounded-lg" src="@/assets/image/neon-placeholder.jpg" alt="">
              </div>
            </a>
            <div class="flex flex-col justify-between flex-1">
              <a href="/blog-post"></a>
              <div class="flex-1">
                <a href="/blog-post">
                  <div class="flex pt-6 space-x-1 text-sm text-gray-500">
                    <time datetime="2020-03-10"> Mar 10, 2020 </time>
                    <span aria-hidden="true"> · </span>
                    <span> 4 min read </span>
                  </div>
                </a>
                <a href="#" class="block mt-2 space-y-6">
                  <h3 class="text-2xl font-semibold leading-none tracking-tighter text-neutral-600">Typography on app.</h3>
                  <p class="text-lg font-normal text-gray-500">Filling text so you can see how it looks like with text. Did I said text?</p>
                </a>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <!-- // New section -->

    <p v-else class="help is-info">No listings found matching your search.</p>
    <p class="help is-danger" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "SearchListings",
  data() {
    return {
      searchQuery: "",
      houses: [],
      filteredHouses: [],
      error: "",
    };
  },
  created() {
    this.fetchHouses();
  },
  methods: {
    fetchHouses() {
      api
        .get("/api/houses/all")
        .then((response) => {
          this.houses = response.data;
          this.filteredHouses = this.houses;
        })
        .catch((error) => {
          console.log(error);
          this.error = "Failed to fetch house listings";
        });
    },
    searchListings() {
      const query = this.searchQuery.toLowerCase();
      if (query) {
        this.filteredHouses = this.houses.filter((house) => {
          return (
            house.title.toLowerCase().includes(query) ||
            house.description.toLowerCase().includes(query) ||
            house.location.toLowerCase().includes(query)
          );
        });
      } else {
        this.filteredHouses = this.houses;
      }
    },
  },
};
</script>

<style scoped>

</style>
