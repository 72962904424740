<template>
    <div class="listings">
      <section class="section">
        <div class="container">
          <div class="level">
            <div class="level-left">
              <div>
                <h1 class="title">All Listings</h1>
                <p class="subtitle">{{ totalListings }} listings available</p>
              </div>
            </div>
            <div class="level-right">
              <router-link to="/" class="button is-primary">Back to Homepage</router-link>
              <router-link to="/signup" class="button is-link cta-button">Create an Account</router-link>
            </div>
          </div>
          <div class="field">
            <p class="control has-icons-left">
              <input class="input" type="text" v-model="searchQuery" @input="searchListings" placeholder="Search by location, title, description...">
              <span class="icon is-left">
                <i class="fas fa-search"></i>
              </span>
            </p>
          </div>
          <div class="columns is-multiline">
            <div class="column is-one-third" v-for="listing in visibleListings" :key="listing._id">
              <div class="card">
                <div class="card-image">
                  <figure class="image is-4by3">
                    <router-link :to="'/house/' + listing._id">
                      <img :src="listing.photos[0]" alt="House photo">
                    </router-link>
                  </figure>
                </div>
                <div class="card-content">
                  <p class="title">{{ listing.title }}</p>
                  <p class="subtitle">{{ listing.description }}</p>
                  <router-link :to="'/house/' + listing._id" class="button is-link">View Details</router-link>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loading" class="has-text-centered">
            <p>Loading more listings...</p>
          </div>
          <div v-if="allLoaded" class="has-text-centered">
            <p>All listings loaded.</p>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import api from '@/services/api';
  
  export default {
    name: 'Listings',
    data() {
      return {
        listings: [],
        visibleListings: [],
        totalListings: 0,
        error: '',
        loading: false,
        allLoaded: false,
        limit: 6,
        offset: 0,
        searchQuery: ''
      };
    },
    created() {
      this.fetchListings();
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      async fetchListings() {
        try {
          this.loading = true;
          const response = await api.get('/api/houses/all', {
            params: {
              limit: this.limit,
              offset: this.offset
            }
          });
          if (response.data.length < this.limit) {
            this.allLoaded = true;
          }
          this.listings = this.listings.concat(response.data);
          this.visibleListings = this.listings.slice(0, this.offset + this.limit);
          this.offset += this.limit;
          this.totalListings = this.listings.length;
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.error = 'Failed to fetch listings';
          this.loading = false;
        }
      },
      handleScroll() {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 500 && !this.loading && !this.allLoaded) {
          this.fetchListings();
        }
      },
      searchListings() {
        if (this.searchQuery.trim() === '') {
          this.visibleListings = this.listings.slice(0, this.offset);
        } else {
          const query = this.searchQuery.toLowerCase();
          this.visibleListings = this.listings.filter(listing => {
            return (
              listing.title.toLowerCase().includes(query) ||
              listing.description.toLowerCase().includes(query) ||
              listing.location.toLowerCase().includes(query)
            );
          });
        }
      }
    }
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
  
  .listings {
    padding: 3rem 1.5rem;
    color: #fff;
  }
  
  .section {
    padding: 3rem 1.5rem;
  }
  
  .level {
    margin-bottom: 2rem;
  }
  
  .title {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    color: #3273dc;
  }
  
  .subtitle {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #3273dc;
  }
  
  .card {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
    border-radius: 5px;
    margin: 1rem 0;
    transition: transform 0.2s;
    background-color: #fff;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-image img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .button.is-primary {
    background-color: #3273dc;
    border-color: transparent;
    color: #fff;
  }
  
  .button.is-link {
    background-color: #00d1b2;
    border-color: transparent;
    color: #fff;
  }
  
  .has-text-centered {
    text-align: center;
    margin-top: 2rem;
  }
  
  .cta-button {
    margin-left: 10px;
  }
  
  .field {
    margin-bottom: 1.5rem;
  }
  
  .control.has-icons-left .input {
    padding-left: 2.5rem;
  }
  
  .icon.is-left {
    left: 8px;
  }
  
  .input {
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 0.75rem;
    background-color: #fff;
    color: #333;
  }
  
  .input::placeholder {
    color: #888;
  }
  
  .input:focus {
    border-color: #3273dc;
    box-shadow: 0 0 0 0.125em rgba(50, 115, 220, 0.25);
  }
  
  @media screen and (max-width: 768px) {
    .level {
      flex-direction: column;
      align-items: flex-start;
    }
    .level-right {
      margin-top: 1rem;
      align-self: stretch;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .cta-button {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
  </style>
  