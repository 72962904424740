<template>
  <div class="profile">
    <section class="">
      <div class="items-center px-5 py-12 lg:px-20">
        <h1 class="">Your Profile</h1>
        <div
          class="border-solid border-2 border-sky-500 flex flex-col w-full max-w-md p-10 mx-auto my-6 transition duration-500 ease-in-out transform bg-white rounded-lg md:mt-0"
        >
          <div class="mt-8">
            <form @submit.prevent="updateProfile" class="space-y-6">
              <div>
                <label
                  for="first_name"
                  class="block text-sm font-medium text-neutral-600"
                >
                  First name
                </label>
                <div class="mt-1">
                  <input
                    v-model="user.firstName"
                    name="first_name"
                    type="text"
                    autocomplete="given-name"
                    required
                    placeholder="Enter your first name"
                    class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label for="last_name" class="block text-sm font-medium text-neutral-600">
                  Last name
                </label>
                <div class="mt-1">
                  <input
                    v-model="user.lastName"
                    name="last_name"
                    type="text"
                    autocomplete="family-name"
                    required
                    placeholder="Enter your last name"
                    class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label for="username" class="block text-sm font-medium text-neutral-600">
                  Username
                </label>
                <div class="mt-1">
                  <input
                    v-model="user.username"
                    name="username"
                    type="text"
                    required
                    placeholder="Enter your username"
                    class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                </div>
              </div>

              <div>
                <label for="email" class="block text-sm font-medium text-neutral-600">
                  Email
                </label>
                <div class="mt-1">
                  <input
                    v-model="user.email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    required
                    placeholder="Enter your email"
                    class="block w-full px-5 py-3 text-base text-neutral-600 placeholder-gray-300 transition duration-500 ease-in-out transform border border-transparent rounded-lg bg-gray-50 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-300"
                  />
                </div>
              </div>

              <!-- TODO: add image upload; don't allow non-real person images. -->
              <!--
                <div class="field">
                  <label class="label">Profile Picture URL</label>
                  <div class="control">
                    <input class="input" type="text" v-model="user.profilePicture" placeholder="Enter your profile picture URL">
                  </div>
                </div>
              -->

              <div>
                <button
                  type="submit"
                  class="flex items-center justify-center w-full px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Update
                </button>
              </div>
            </form>

            <!-- TODO: these should be alerts. -->
            <p class="help is-danger" v-if="error">{{ error }}</p>
            <p class="help is-success" v-if="success">{{ success }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  name: "Profile",
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        profilePicture: "",
      },
      error: "",
      success: "",
    };
  },
  created() {
    this.checkToken();
    this.fetchProfile();
  },
  methods: {
    checkToken() {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$router.push("/login");
      }
    },
    async fetchProfile() {
      try {
        const token = localStorage.getItem("token"); // Get the token from local storage
        if (!token) {
          throw new Error("No token provided");
        }
        const response = await api.get("/api/users/me", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
          },
        });
        this.user = response.data;
      } catch (error) {
        console.error("Error fetching profile:", error); // Debugging
        this.error = "Failed to fetch profile";
      }
    },
    async updateProfile() {
      try {
        const token = localStorage.getItem("token"); // Get the token from local storage
        if (!token) {
          throw new Error("No token provided"); // todo: just show error, and redirect to login
        }
        const response = await api.put("/api/users/me", this.user, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the headers
          },
        });
        this.success = "Profile updated successfully";
        this.error = "";
      } catch ({ error }) {
        console.error("Error updating profile:", error); // Debugging
        this.error = "Failed to update profile, " + error;
        this.success = "";
      }
    },
  },
};
</script>

<style scoped></style>
